<template>
  <div class="admin-user-page">
    <admin-nav-bar></admin-nav-bar>
    <div class="container">
      <h1>JellyCrew 사용자 권한 부여</h1>
      <p>JellyCrew NFT 컨텐츠 생성 권한을 부여하는 메뉴입니다.</p>
      <div class="jellycrew-add-member">
        <input
          type="text"
          v-model="addUserId"
          placeholder="추가할 아이디(user_id)를 입력해 주세요."
        />
        <button class="add-member-btn" @click="onClickAddMember">
          추가하기
        </button>
      </div>
      <div class="jellycrew-members">
        <h2>JellyCrew 사용자 목록</h2>
        <table>
          <tr>
            <th>user_id</th>
            <th>page_id</th>
            <th>닉네임</th>
            <th>정보</th>
            <th>Jellycrew권한</th>
          </tr>
          <tr
            v-for="item in userListObject.paginationData"
            v-bind:key="item._id"
          >
            <td>{{ item.user_id }}</td>
            <td>{{ item.pageId }}</td>
            <td>{{ item.name }}</td>
            <td>
              <button class="table-btn" @click="onClickInformation(item)">
                보기
              </button>
            </td>
            <td>
              <button
                class="table-btn danger"
                @click="onClickDeleteMember(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <modal v-if="showModal" @close="handleCloseModal">
      <template v-slot:header>
        <h1>사용자 정보</h1>
      </template>
      <template v-slot:body>
        <h3>닉네임</h3>
        <p>{{ modalData.name }}</p>
        <h3>프로필 이미지</h3>
        <p><img class="modal-profile-img" :src="modalData.profileImgUrl" /></p>
        <h3>자기소개</h3>
        <p>{{ modalData.message }}</p>
        <h3>sns 정보</h3>
        <div
          class="sns-modal-container"
          v-for="sns in modalData.sns"
          v-bind:key="sns.code"
        >
          <h5>{{ sns.code }}</h5>
          <p>{{ sns.url }}</p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  searchUsers,
  getUserInfo,
  setJellycrewRole,
  removeJellycrewRole
} from "@/common/api.service";
import { checkAdmin } from "@/common/auth.service";
import AdminNavBar from "@/components/admin/Navbar";
import Modal from "@/components/admin/Modal";

const PER_PAGE = 100;

export default {
  name: "User",
  components: {
    AdminNavBar,
    Modal
  },
  data() {
    return {
      addUserId: "",
      showModal: false,
      modalData: {},
      userListObject: {}
    };
  },
  async created() {
    const isAdmin = await checkAdmin();

    if (!isAdmin) {
      this.$router.push("/");
    }

    //젤리크루 사용자 리스트를 불러온다.
    this.getJellycrewUsers();
  },
  methods: {
    getJellycrewUsers() {
      searchUsers("", PER_PAGE).then(res => {
        if (res && res.data) {
          this.userListObject = res.data[0];
        }
      });
    },
    onClickAddMember() {
      if (!this.addUserId) {
        alert("아이디를 입력해 주세요.");
        return;
      }

      setJellycrewRole().then(res => {
        if (res && res.data) {
          this.getJellycrewUsers();
          this.addUserId = "";
        }
      });
    },
    onClickDeleteMember(data) {
      if (!window.confirm("Jellycrew 권한을 제거하시겠습니까?")) return;

      removeJellycrewRole(data.user_id).then(res => {
        if (res && res.data) {
          this.getJellycrewUsers();
        }
      });
    },
    onClickInformation(data) {
      getUserInfo(data.pageId).then(res => {
        if (res && res.data) {
          this.showModal = true;
          this.modalData = res.data[0];
        }
      });
    },
    handleCloseModal() {
      this.showModal = false;
      this.modalData = {};
    }
  }
};
</script>

<style scoped>
.admin-user-page {
  display: flex;
  border-top: 1px solid #f1f1f1;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.jellycrew-members {
  width: 100%;
}

.jellycrew-add-member {
  display: flex;
}

.jellycrew-add-member input {
  width: 250px;
}

.add-member-btn {
  margin-left: 15px;
  background-color: #ba7fd1;
  border: none;
  color: white;
  height: 30px;
  width: 100px;
  cursor: pointer;
}

.modal-profile-img {
  max-width: 200px;
}
</style>
